import React, { useState } from 'react';
import './App.css';
import mainmenu from './img/mainmenu.png';
import classes from './img/classes.png';
import homeworks from './img/homeworks.png';
import students from './img/students.png';
import attendence from './img/attendence.png';
import assignaclass from './img/assignaclass.png';

function Sms() {
  const [selectedImage, setSelectedImage] = useState(null);

  const styles = {
    header: {
     
      padding: '40px 20px',
      borderRadius: '12px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontSize: '2.5rem',
      
      marginBottom: '10px',
    },
    description: {
      fontSize: '1.1rem',
      
      lineHeight: '1.6',
      maxWidth: '800px',
      margin: '0 auto 40px auto',
    },
    section: {
      marginTop: '40px',
    },
    subtitle: {
      fontSize: '2rem',
   
      marginBottom: '20px',
      borderBottom: '3px solid #FFD700',
      display: 'inline-block',
      paddingBottom: '5px',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '20px',
      padding: '20px 0',
    },
    imageCard: {
      backgroundColor: '#333',
      borderRadius: '12px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
    imageCardHover: {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
    },
    image: {
      width: '100%',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    imageDescription: {
      padding: '15px',
      fontSize: '1rem',
      
      textAlign: 'left',
    },
    // Modal stilleri
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    modalContent: {
      position: 'relative',
     
      padding: '20px',
      borderRadius: '12px',
      maxWidth: '90%',
      maxHeight: '90%',
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
    },
    modalImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '12px',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '15px',
      backgroundColor: '#FFD700',
      border: 'none',
      padding: '10px',
      borderRadius: '50%',
      cursor: 'pointer',
      fontSize: '1.2rem',
    }
  };

  return (
    <div className="App">
      <header className="App-header" style={styles.header}>
        <h1 className='App-title' style={styles.title}>Student Management System</h1>
        <p className='App-description' style={styles.description}>
          Originally named "ÖğrenciBis," which translates to "Student Management System" from Turkish, 
          this project allows you to manage almost every aspect of your school or private course.
          The Admin section covers classes, students, teachers, homework, exams, and more.
          A mobile app version for teachers enables homework assignments and attendance tracking.
          The backend uses .NET Core, EntityFramework, and PostgreSQL, while the frontend is developed with Blazor and MudBlazor.
        </p>

        <section className='App-section' style={styles.section}>
          <h2 className='App-subtitle' style={styles.subtitle}>Admin Panel</h2>
          <div className='App-grid' style={styles.grid}>
            <ProjectImage src={mainmenu} alt="Main Menu Screen" description="The home screen features default cards for Students, Teachers, and Classes." styles={styles} onClick={() => setSelectedImage(mainmenu)} />
            <ProjectImage src={classes} alt="Classes Screen" description="View, add, modify, or delete classes." styles={styles} onClick={() => setSelectedImage(classes)} />
            <ProjectImage src={homeworks} alt="Homeworks Screen" description="View all homework assignments and check their completion status." styles={styles} onClick={() => setSelectedImage(homeworks)} />
            <ProjectImage src={students} alt="Students Screen" description="Manage student records and registered parents." styles={styles} onClick={() => setSelectedImage(students)} />
            <ProjectImage src={attendence} alt="Attendance Screen" description="Take attendance and generate reports for students or classes." styles={styles} onClick={() => setSelectedImage(attendence)} />
            <ProjectImage src={assignaclass} alt="Assign A Class Screen" description="Assign teachers to classes." styles={styles} onClick={() => setSelectedImage(assignaclass)} />
          </div>
        </section>
      </header>

      {/* Modal */}
      {selectedImage && (
        <div style={styles.modalOverlay} onClick={() => setSelectedImage(null)}>
          <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <button style={styles.closeButton} onClick={() => setSelectedImage(null)}>×</button>
            <img src={selectedImage} alt="Preview" style={styles.modalImage} />
          </div>
        </div>
      )}
    </div>
  );
}

function ProjectImage({ src, alt, description, styles, onClick }) {
  return (
    <div 
      className='App-image-card' 
      style={styles.imageCard}
      onMouseEnter={(e) => e.currentTarget.style = { ...styles.imageCard, ...styles.imageCardHover }}
      onMouseLeave={(e) => e.currentTarget.style = styles.imageCard}
      onClick={onClick}
    >
      <img src={src} alt={alt} style={styles.image} />
      <p className='App-image-description' style={styles.imageDescription}>{description}</p>
    </div>
  );
}

export default Sms;
